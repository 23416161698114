import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  fontSize,
} from '../styles';
import { Container, AnimatedWords, AnimatedBlock, Link } from './ui';
import ModularBlocks from './ModularBlocks';
import { RelatedArticles } from './RelatedBlogPosts';
import FeaturedProduct from './FeaturedProduct';
import { H1 } from './typography/Typography';
import { Slideshow } from './Slideshow/Slideshow';

const StyledBlogArticle = styled.article``;

const StyledInner = styled.div`
  ${({ altLayout }) => {
    if (altLayout) {
      return css`
        ${minBreakpointQuery.small`
          display: grid;
          grid-template-columns: 1.8fr 1fr;
          column-gap: 20px;
        `}

        ${minBreakpointQuery.mlarge`
          column-gap: 30px;
        `}

        ${minBreakpointQuery.xlarge`
          column-gap: 40px;
        `}

        ${minBreakpointQuery.xxxlarge`
          column-gap: 50px;
          padding-left: 50px;
        `}
      `;
    }
  }}
`;

const StyledHeader = styled.header`
  ${sectionMargins('60px', '160px')};

  ${({ altLayout }) => {
    if (altLayout) {
      return css`
        margin-bottom: 50px;

        ${minBreakpointQuery.tiny`
          margin-bottom: 60px;
        `}

        ${minBreakpointQuery.small`
          margin-right: auto;
        `}

        ${minBreakpointQuery.xxxxlarge`
          margin-bottom: 70px;
        `}
      `;
    }
  }}
`;

const StyledHeading = styled(H1)`
  ${({ altLayout }) => {
    if (!altLayout) {
      return css`
        text-align: center;
      `;
    }
  }}
`;

const StyledBannerImage = styled(GatsbyImage)`
  margin-top: 40px;

  ${minBreakpointQuery.small`
    margin-top: 50px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 60px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 70px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 80px;
  `}
`;

const StyledIntro = styled.section`
  ${({ altLayout }) => {
    if (altLayout) {
      return css`
        ${minBreakpointQuery.small`
          grid-column: 1 / 2;
          margin-right: auto;
        `}
      `;
    }
  }}
`;

const StyledContainer = styled(Container)`
  ${({ alt }) => {
    if (alt) {
      return css`
        ${maxBreakpointQuery.large`
          padding-right: 0;
          padding-left: 0;
        `}
      `;
    } else {
      return css`
        padding-right: 0;
        padding-left: 0;
      `;
    }
  }}
`;

const StyledIntroInner = styled(AnimatedBlock)`
  ${({ altLayout }) => {
    if (!altLayout) {
      return css`
        ${minBreakpointQuery.large`
          position: relative;
        `}
      `;
    }
  }}
`;

const StyledMetaText = styled.div`
  ${({ altLayout }) => {
    if (altLayout) {
      return css`
        ${minBreakpointQuery.large`
          display: grid;
          grid-template-columns: 1fr 2.8fr;
          gap: 30px;
        `}
      `;
    }
  }}
`;

const StyledMeta = styled.div`
  ${({ altLayout }) => {
    if (altLayout) {
      return css`
        ${maxBreakpointQuery.large`
          margin-bottom: 30px;
        `}
      `;
    } else {
      return css`
        ${maxBreakpointQuery.large`
          margin-bottom: 50px;
        `}

        ${minBreakpointQuery.large`
          position: absolute;
          top: 0;
          left: 0;
          max-width: 200px;
        `}
      `;
    }
  }}
`;

const StyledCategories = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
  margin-bottom: 6px;
`;

const commonTypographyStyles = () => {
  return css`
    line-height: 1.4;

    ${minBreakpointQuery.small`
      ${fontSize(18)};
    `}
  `;
};

const StyledCategory = styled.li`
  ${commonTypographyStyles()};

  &:after {
    content: ',';
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const StyledTime = styled.time`
  display: block;
  margin-top: 6px;
  ${commonTypographyStyles()};
`;

const StyledText = styled.p`
  ${commonTypographyStyles()};

  ${minBreakpointQuery.medium`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(23)};
  `}
`;

const StyledImages = styled.section`
  display: none;

  ${maxBreakpointQuery.small`
    margin-top: 60px;
    margin-bottom: 60px;
  `}

  ${minBreakpointQuery.small`
    display: block;
    grid-row: 1 / 5;
    grid-column: 2 / 3;
  `}
`;

const StyledImage = styled(GatsbyImage)``;

const StyledSlideShowWrapper = styled.div`
  margin-top: 32px;

  ${minBreakpointQuery.small`
          display: none;
        `}
`;

const StyledContent = styled.div`
  ${({ altLayout }) => {
    if (altLayout) {
      return css`
        ${minBreakpointQuery.small`
          position: sticky;
          top: 0;
          margin-right: auto;
        `}
      `;
    }
  }}
`;

const StyledFeaturedProduct = styled(FeaturedProduct)`
  ${({ isBlogArticleOrigLayout }) => {
    if (!isBlogArticleOrigLayout) {
      return css`
        ${minBreakpointQuery.xxxlarge`
          padding-left: 90px;
        `}
      `;
    }
  }}
`;

const StyledRelatedArticles = styled(RelatedArticles)`
  ${({ isBlogArticleOrigLayout }) => {
    if (!isBlogArticleOrigLayout) {
      return css`
        ${minBreakpointQuery.xxxlarge`
          padding-left: 90px;
        `}
      `;
    }
  }}
`;

const BlogArticle = ({
  heading,
  bannerImage,
  categories,
  firstPublishedAt,
  introduction,
  images,
  modularBlocks,
  featuredProduct,
  relatedBlogPosts,
  altLayout,
}) => (
  <StyledBlogArticle>
    <StyledContainer wide={true}>
      <StyledInner altLayout={altLayout}>
        <StyledHeader altLayout={altLayout}>
          <Container narrower={altLayout} narrow={!altLayout}>
            <StyledHeading altLayout={altLayout}>
              <AnimatedWords>{heading}</AnimatedWords>
            </StyledHeading>
          </Container>
          {bannerImage && (
            <Container narrower={altLayout} wide={!altLayout}>
              <StyledBannerImage
                image={bannerImage.gatsbyImageData}
                alt={bannerImage.alt}
              />
            </Container>
          )}
        </StyledHeader>
        <StyledIntro altLayout={altLayout}>
          <StyledContainer alt={!altLayout}>
            <StyledIntroInner altLayout={altLayout}>
              <Container narrower={true}>
                <StyledMetaText altLayout={altLayout}>
                  <StyledMeta altLayout={altLayout}>
                    {categories.length > 0 && (
                      <StyledCategories>
                        {categories.map(({ text, slug }, id) => (
                          <StyledCategory key={id}>
                            <StyledLink to={`blog/${slug}`}>{text}</StyledLink>
                          </StyledCategory>
                        ))}
                      </StyledCategories>
                    )}
                    <StyledTime dateTime={firstPublishedAt.robotDate}>
                      {firstPublishedAt.humanDate}
                    </StyledTime>
                  </StyledMeta>
                  <StyledText>{introduction}</StyledText>
                </StyledMetaText>
              </Container>
            </StyledIntroInner>
          </StyledContainer>
        </StyledIntro>
        {Boolean(images?.length) && (
          <StyledSlideShowWrapper>
            <Slideshow
              aspectRatio={0.75}
              items={images}
              renderItem={({ gatsbyImageData, alt }, id) => {
                return (
                  <StyledImage key={id} image={gatsbyImageData} alt={alt} />
                );
              }}
            />
          </StyledSlideShowWrapper>
        )}
        {Boolean(images?.length) && (
          <StyledImages>
            {images.map(({ gatsbyImageData, alt }, id) => (
              <StyledImage key={id} image={gatsbyImageData} alt={alt} />
            ))}
          </StyledImages>
        )}
        <StyledContent altLayout={altLayout}>
          <StyledContainer narrower={altLayout}>
            <ModularBlocks items={modularBlocks} isBlogSingle={true} />
          </StyledContainer>
        </StyledContent>
      </StyledInner>
    </StyledContainer>
    {featuredProduct && (
      <StyledFeaturedProduct
        {...featuredProduct}
        isBlogArticle={true}
        isBlogArticleOrigLayout={!altLayout}
      />
    )}
    <StyledRelatedArticles
      items={relatedBlogPosts}
      isBlogArticleOrigLayout={!altLayout}
    />
  </StyledBlogArticle>
);

export default BlogArticle;
