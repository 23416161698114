import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BlogArticle from '../components/BlogArticle';

const BlogSingleTemplate = ({
  data: {
    datoCmsBlog: {
      seoMetaTags,
      title,
      firstPublishedAt,
      categories,
      bannerImage,
      altLayoutBannerImage,
      introduction,
      images,
      modularBlocks,
      relatedBlogPosts,
    },
    shopifyProduct,
  },
}) => {
  const altLayout = images && images.length > 0 ? true : false;

  return (
    <Layout removeLinesBackground={true} seo={seoMetaTags}>
      <main>
        <BlogArticle
          heading={title}
          bannerImage={altLayout ? altLayoutBannerImage : bannerImage}
          categories={categories}
          firstPublishedAt={firstPublishedAt}
          introduction={introduction}
          images={images}
          modularBlocks={modularBlocks}
          featuredProduct={shopifyProduct}
          relatedBlogPosts={relatedBlogPosts}
          altLayout={altLayout}
        />
      </main>
    </Layout>
  );
};

export const BlogSingleTemplateQuery = graphql`
  query BlogSingleTemplateQuery($id: String!, $featuredProduct: String) {
    datoCmsBlog(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      firstPublishedAt: meta {
        robotDate: firstPublishedAt
        humanDate: firstPublishedAt(formatString: "DD / MM / YY")
      }
      categories {
        text: title
        slug
      }
      bannerImage {
        gatsbyImageData(width: 1520, height: 860)
        alt
      }
      altLayoutBannerImage: bannerImage {
        gatsbyImageData(width: 670, height: 380)
        alt
      }
      introduction
      images {
        gatsbyImageData(width: 770, height: 1030)
        alt
      }
      modularBlocks {
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...HighlightedImagesModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
      relatedBlogPosts {
        ...BlogCardFragment
      }
    }
    shopifyProduct(handle: { eq: $featuredProduct }) {
      ...ProductCardFragment
    }
  }
`;

export default BlogSingleTemplate;
